.navBarItem {
  padding: 24px;
  font-family: 'Helvetica Neue', Helvetica, Arial, sans-serif;
  cursor: pointer;
}

.navbarContainer {
  padding: 1.3rem 6rem;
  transition: box-shadow 1s ease-in-out;
}

.navbarContent {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.homeIcon {
  cursor: pointer;
  height: 2.5rem;
  width: 2.5rem;
}

.navItems {
  display: flex;
  gap: 50px;
}

.navLink {
  cursor: pointer;
}

@media (max-width: 768px) {
  .navbarContainer {
    padding: 1.3rem 3rem;
    transition: box-shadow 1s ease-in-out;
  }
  

  @media (max-width: 490px) {
    .navbarContainer {
      padding: 1.3rem 3rem;
      transition: box-shadow 1s ease-in-out;
    }

    .homeIcon {
      cursor: pointer;
      height: 2rem;
      width: 2rem;
    }
  }
}
