p {
  font-family: 'Poppins', sans-serif;
  font-style: normal;
  color: #1f1f1f;
}

.header {
  background-color: blue;
  color: white;
  padding: 100px;
}

.page {
  display: grid;
  grid-template-columns: 1fr;
  align-items: center;
  justify-items: center;
  justify-content: center;
  overflow: hidden;
}

.banner {
  display: grid;
  grid-template-columns: min-content 1fr;
  width: 100%;
  max-width: 2000px;
  margin-top: 50px;

}

.banner img {
  width: 100%; // Makes the image responsive
  max-width: 1500px; // Ensures image does not exceed 200px
  height: auto; // Maintains aspect ratio
}

.titleText {
  font-family: 'Poppins', sans-serif;
  font-weight: 700;
  font-style: normal;
  font-size: 30px; /* Default font size */
  align-self: center;
  margin-left: 30px;
  text-shadow: 3px 5px #ede3ff;
}

.helloWorld {
  margin-top: 20px;
  display: flex;
  width: 87%;
  max-width: 1800px;
  justify-items: flex-start;
  flex-direction: column;
}

.helloWorldText {
  font-family: 'VT323', monospace;
  font-weight: 400;
  font-style: normal;
  font-size: 30px;
}

.introText {
  font-weight: 400;
  font-size: 18px;
  margin-top: 6px;
  text-align: justify;
}

.indexItem {
  display: grid;
  grid-template-columns: 1fr;
  align-items: center;
  justify-items: center;
  justify-content: center;
  width: 100%;

  &.footerVersion {
    width: 100%;
  }
}

.traneIndex {
  display: grid;
  grid-template-columns: 1fr;
  max-width: 2000px;
  margin: 20px 0 30px;
  padding: 0 16px;
  justify-items: center;
}

.dogGateIndex {
  display: grid;
  grid-template-columns: 1fr;
  max-width: 2000px;
  margin: 20px 0 30px;
  padding: 0px 16px;
  justify-items: center;
}

.sushiIndex {
  display: grid;
  grid-template-columns: 1fr;
  max-width: 2000px;
  margin: 20px 0 30px;
  padding: 0 16px;
  justify-items: center;
}

.traneIndexDescription {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  padding: 0 10px;
}

.traneIndex .traneImage {
  width: 100%; // Makes the image responsive
  height: auto; // Maintains aspect ratio
}

.indexTitle {
  font-weight: 600;
  font-size: 20px;
  margin-top: 22px;
}

.indexSubtitle {
  margin-top: 18px;
  font-size: 18px;
  font-weight: 500;
}

.indexDescription {
  margin-top: 8px;
  font-size: 16px;
  font-weight: 400;
  text-align: justify;
}

.indexItem {
  display: grid;
  grid-template-columns: 1fr;
  align-items: center;
  justify-items: center;
  justify-content: center;
  width: 90%;
  border-radius: 20px;
  border: 6px solid #fff;
  margin-top: 50px;

  &.traneBackground {
    background: #b3ddf291;
    box-shadow: 0px 0px 60px 0px rgba(213, 205, 229, 0.5);
  }

  &.dogGateBackground {
    background: #e3d4f399;
    box-shadow: 0px 0px 60px 0px rgba(213, 205, 229, 0.5);
  }

  &.sushiBackground {
    background: #c7e5d4ad;
    box-shadow: 0px 0px 60px 0px rgba(213, 205, 229, 0.5);
  }
}

.pastWorkTitleContainer {
  display: flex;
  justify-content: center;
  gap: 8px;
  margin: 50px 0 0;
  width: 87%;
}

.pastWorkTitle {
  font-size: 22px;
  font-weight: 400;
}

.buttonContainer {
  display: flex;
  width: 100%;
  justify-content: center;
}

.footerContainer {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
}

.footerContainer {
  display: grid;
  grid-template-columns: 1fr;
  margin: 0 0 80px;
  justify-items: center;
  width: 100%;
}

.emailButton {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  background-color: #ffa500; /* Orange background */
  color: white; /* White text */
  width: 300px;
  text-decoration: none; /* Removes underline */
  padding: 15px 20px; /* Top and Bottom Padding, Left and Right Padding */
  border-radius: 50px; /* Rounded corners */
  font-size: 16px; /* Text size */
  font-weight: bold; /* Bold text */
  border: none; /* No border */
  cursor: pointer; /* Pointer cursor on hover */
}

.emailIcon {
  width: 30px;
  height: unset;
  margin-right: 20px; /* Space between icon and text */
}

.emailButtonText {
  font-size: 14px;
  color: white;
}

.messageIcon {
  width: 28px; /* Width of the icon, adjust if different */
  height: unset; /* Height of the icon, adjust if different */
  margin-right: 20px; /* Space between icon and text */
}

.getInTouchText {
  text-align: center;
  margin-top: 70px;
}

.emailText {
  font-size: 20px;
  margin-top: 20px;
}

@media (min-width: 550px) {
  .titleText {
    font-size: 40px;
  }

  .pastWorkTitle {
    font-size: 24px;
  }
}

.aws-btn {
  --button-danger-color: black;
}
