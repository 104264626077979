.page {
  display: grid;
  grid-template-columns: 1fr;
  align-items: center;
  justify-items: center;
  justify-content: center;
  overflow: hidden;
}

.pageContent {
  width: 80%;
  max-width: 2000px;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.coverImage {
  margin: 80px 0 0;
}

.description {
  margin-bottom: 50px;
}

.designProcess {
  margin-bottom: 50px;
}

.designProcessImage {
  margin-bottom: 50px;
}

.theResearch {
  margin-bottom: 50px;
}

.researchInsights {
  margin-bottom: 70px;
}

.userPersonas {
  margin-bottom: 50px;
}

.userPersonasImage {
  margin-bottom: 70px;
}

.userJourney {
  margin-bottom: 50px;
}

.clientBefore {
  margin-bottom: 50px;
}

.clientAfter {
  margin-bottom: 50px;
}

.salesAdministratorJourneyBefore {
  margin-bottom: 70px;
}

.SalesAdministratorJourneyAfter {
  margin-bottom: 50px;
}

.taskFlow {
  margin-bottom: 50px;
}

.taskFlowImage {
  margin-bottom: 30px;
}

.lofiWireframe {
  margin-bottom: 50px;
}

.lofiWireframeImage {
  margin-bottom: 70px;
}

.designSystem {
  margin-bottom: 50px;
}

.designSystemImage {
  margin-bottom: 70px;
  width: 60%;
}

.hifiWireframe {
  margin-bottom: 50px;
}

.usabilityTest {
  margin-bottom: 50px;
}

.whatDidILearn {
  margin-bottom: 100px;
}

.thankYouContainer {
  padding: 40px 50px;
  border-radius: 30px;
  box-shadow: 0 0 40px rgb(238, 238, 238, 0.6);
  background: linear-gradient(
    135deg,
    rgba(248, 231, 140, 0.1),
    rgba(233, 185, 255, 0.1),
    rgba(207, 255, 169, 0.1)
  );
  font-family: 'poppins', sans-serif;
  color: #303030;
  font-size: 20px;
  border: solid 5px #ffffff;
  line-height: 1.8;
  margin-bottom: 150px;
}

.seeOtherProjects {
  margin-bottom: 50px;
}

@media (max-width: 1200px) {
  .thankYouContainer {
    padding: 40px 50px;
    border-radius: 30px;
    box-shadow: 0 0 40px rgb(238, 238, 238, 0.6);
    background: linear-gradient(
      135deg,
      rgba(248, 231, 140, 0.1),
      rgba(233, 185, 255, 0.1),
      rgba(207, 255, 169, 0.1)
    );
    font-family: 'poppins', sans-serif;
    color: #303030;
    font-size: 16px;
    border: solid 5px #ffffff;
    line-height: 1.8;
    margin-bottom: 150px;
  }
}


@media (max-width: 1000px) {
  .designProcess {
    margin-bottom: 30px;
  }

  .designProcessImage {
    margin-bottom: 30px;
  }

  .userPersonasImage{
    margin-bottom: 50px;
  }

.taskFlowImage{
  margin-bottom: 10px;
}

  .thankYouContainer {
    padding: 40px 50px;
    border-radius: 30px;
    box-shadow: 0 0 40px rgb(238, 238, 238, 0.6);
    background: linear-gradient(
      135deg,
      rgba(248, 231, 140, 0.1),
      rgba(233, 185, 255, 0.1),
      rgba(207, 255, 169, 0.1)
    );
    font-family: 'poppins', sans-serif;
    color: #303030;
    font-size: 14px;
    border: solid 5px #ffffff;
    line-height: 1.8;
    margin-bottom: 100px;
  }

  .seeOtherProjects {
    margin-bottom: 40px;
  }
}