p {
  font-family: 'Poppins', sans-serif;
  font-style: normal;
  color: #1f1f1f;
}

.header {
  background-color: blue;
  color: white;
  padding: 100px;
}

.page {
  display: grid;
  grid-template-columns: 1fr;
  align-items: center;
  justify-items: center;
  justify-content: center;
  overflow: hidden;
}

.banner {
  display: grid;
  grid-template-columns: min-content 1fr;
  width: 100%;
  max-width: 2000px;
}

.banner img {
  width: 100%; // Makes the image responsive
  max-width: 1500px; // Ensures image does not exceed 200px
  height: auto; // Maintains aspect ratio
}

.titleText {
  font-family: 'Poppins', sans-serif;
  font-weight: 700;
  font-style: normal;
  font-size: 100px; /* Default font size */
  align-self: center;
  margin-left: 50px;
  text-shadow: 4px 10px #ede3ff;
}

.helloWorld {
  margin-top: 60px;
  display: flex;
  width: 80%;
  max-width: 1800px;
  justify-items: flex-start;
  flex-direction: column;
}

.helloWorldText {
  font-family: 'VT323', monospace;
  font-weight: 400;
  font-style: normal;
  font-size: 70px;
}

.introText {
  font-weight: 400;
  font-size: 24px;
  text-align: justify;
}

.indexItem {
  display: grid;
  grid-template-columns: 1fr;
  align-items: center;
  justify-items: center;
  justify-content: center;
  width: 80%;
  max-width: 1800px;
  border-radius: 20px;
  border: 8px solid #fff;
  margin-bottom: 100px;

  &.traneBackground {
    background: #b3ddf291;
    box-shadow: 0px 0px 60px 0px rgba(213, 205, 229, 0.5);
  }

  &.dogGateBackground {
    background: #e3d4f399;
    box-shadow: 0px 0px 60px 0px rgba(213, 205, 229, 0.5);
  }

  &.sushiBackground {
    background: #c7e5d4ad;
    box-shadow: 0px 0px 60px 0px rgba(213, 205, 229, 0.5);
  }

  &.footerVersion {
    width: 100%;
  }
}

.traneIndex,
.dogGateIndex,
.sushiIndex {
  display: grid;
  grid-template-columns: 1fr 1fr;
  max-width: 2000px;
  margin: 40px 0;
  padding: 0 40px 0 70px;
  grid-column-gap: 50px;
}

.dogGateIndex,
.sushiIndex,
.traneIndex {
  img {
    width: 100%;
    max-width: 1100px;
    height: auto;
    border-width: 1px;
    border-style: solid;
    border-radius: 20px;
    align-self: center;
  }
}

.dogGateIndex img {
  border-color: #e4d1f8;
}

.sushiIndex img {
  border-color: #bbddcb;
}

.traneIndex img {
  border-color: #c6e9ff;
}

@media (max-width: 1000px) {
  .traneIndex,
  .dogGateIndex,
  .sushiIndex {
    grid-template-columns: 1fr;
    padding: 0 20px;
  }

  .traneIndex img,
  .dogGateIndex img,
  .sushiIndex img {
    max-width: 80%;
    margin-bottom: 40px;
    align-self: center;
    justify-self: center;
    margin-top: 10px;
  }
}

.traneIndexDescription {
  display: flex;
  flex-direction: column;
  justify-content: center;
  justify-items: flex-start;
  margin-left: 10%;
}

.indexTitle {
  font-weight: 500;
  font-size: 35px;
}

.indexSubtitle {
  margin-top: 50px;
  font-size: 25px;
  font-weight: 500;
}

.indexDescription {
  margin-top: 12px;
  font-size: 22px;
  font-weight: 400;
}

.pastWorkTitleContainer {
  display: flex;
  justify-content: center;
  gap: 14px;
  margin: 150px 0 80px;
  text-wrap: nowrap;
}

.dots {
  max-width: 100%;
  height: 17px;
  width: auto;
  align-self: center;
}

.pastWorkTitle {
  font-size: 40px;
}

.footerContainer {
  display: grid;
  justify-items: center;
  grid-template-columns: 1fr 300px 300px;
  column-gap: 30px;
  margin: 0 0 100px;
  align-items: end;
  justify-content: center;
  align-content: center;
}

.emailButton {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  background-color: #ffa500; /* Orange background */
  color: white; /* White text */
  width: 300px;
  text-decoration: none; /* Removes underline */
  padding: 20px 20px; /* Top and Bottom Padding, Left and Right Padding */
  border-radius: 50px; /* Rounded corners */
  font-size: 16px; /* Text size */
  font-weight: bold; /* Bold text */
  border: none; /* No border */
  cursor: pointer; /* Pointer cursor on hover */
}

.emailIcon {
  width: 40px;
  height: 31px;
  margin-right: 20px; /* Space between icon and text */
}

.emailButtonText {
  font-size: 18px;
  color: white;
}

.messageIcon {
  width: 39px; /* Width of the icon, adjust if different */
  height: 33px; /* Height of the icon, adjust if different */
  margin-right: 20px; /* Space between icon and text */
}

.getInTouchText {
  font-weight: 600;
  margin-right: 60px;
  margin-bottom: 10px;
}

.emailText {
  font-size: 20px;
  margin-bottom: 15px;
}

@media (max-width: 1600px) {
  .titleText {
    font-size: 80px; /* Smaller font size at 1000px screen width */
  }
  .introText {
    font-size: 24px;
  }
  .helloWorldText {
    font-size: 60px;
  }
  .indexTitle {
    font-size: 30px;
  }
  .indexSubtitle {
    font-size: 20px;
    margin-top: 20px;
  }
  .indexDescription {
    font-size: 17px;
    margin-top: 8px;
  }
}

/* When the screen width is 400px or less */
@media (max-width: 1100px) {
  .titleText {
    font-size: 50px; /* Even smaller font size at 400px screen width */
  }
  .introText {
    font-size: 19px;
  }
  .helloWorldText {
    font-size: 40px;
  }
  .indexTitle {
    font-size: 25px;
  }

  .pastWorkTitle {
    font-size: 35px;
  }

  .getInTouchText {
    text-align: center;
    margin-right: 0;
  }
}

@media (max-width: 900px) {
  .footerContainer {
    display: grid;
    grid-template-columns: 1fr;
    row-gap: 30px;
    margin: 0 0 100px;
    justify-items: center;
    width: 100%;
  }
}
