.slick-prev,
.slick-next {
  z-index: 1;
  width: 40px;
  height: 40px;
}

.slick-prev {
  left: -10px;
}

.slick-next {
  right: -10px;
}

.slick-prev:before,
.slick-next:before {
  color: black;
  font-size: 40px;
  opacity: 0.75;
}

.slick-prev:hover:before,
.slick-next:hover:before {
  opacity: 1;
}
