.page {
  display: grid;
  grid-template-columns: 1fr;
  align-items: center;
  justify-items: center;
  justify-content: center;
  overflow: hidden;
  background-color: white;
}

.pageContent {
  padding: 120px 0;
  width: 80%;
  max-width: 2000px;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.titleLarge {
  font-size: 80px;
  align-self: start;
  margin-bottom: 70px;
}

.titleMedium {
  font-size: 60px;
  align-self: start;
  margin-bottom: 70px;
}

.titleMobile {
  font-size: 30px;
  align-self: start;
  margin-bottom: 40px;
}

.iconsMobile {
  margin-bottom: 30px;
}

@media (max-width: 430px) {
  .pageContent {
    padding: 100px 0;
    width: 80%;
    max-width: 2000px;
    display: flex;
    flex-direction: column;
    align-items: center;
  }
}