.page {
  display: grid;
  grid-template-columns: 1fr;
  align-items: center;
  justify-items: center;
  justify-content: center;
  overflow: hidden;
}

.pageContent {
  width: 90%;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.cover {
  margin: 80px 0 10px 0;
}

.description {
  margin-bottom: 30px;
}

.lofiWireframe {
  margin-bottom: 25px;
}

.lofiWireframeImage {
  margin-bottom: 40px;
}

.designSystem {
  margin-bottom: 25px;
}

.designSystemImage {
  margin-bottom: 40px;
}

.hifiWireframe {
  margin-bottom: 25px;
}

.conclusion {
  margin-bottom: 40px;
}

.whatIWouldImprove {
  margin-bottom: 70px;
}

.thankYouContainer {
  padding: 10px 14px;
  border-radius: 30px;
  box-shadow: 0 0 40px rgb(238, 238, 238, 0.6);
  background: linear-gradient(
    135deg,
    rgba(248, 231, 140, 0.1),
    rgba(233, 185, 255, 0.1),
    rgba(207, 255, 169, 0.1)
  );
  font-family: 'poppins', sans-serif;
  color: #303030;
  font-size: 15px;
  border: solid 3px #ffffff;
  line-height: 1.8;
  margin-bottom: 70px;
  max-width: 840px;
}
